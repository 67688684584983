import { FaGithub, FaLink, FaBehance } from "react-icons/fa";

const Card = ({ project }) => {
  return (
    <div className="card">
      <h4 className="card-header">
        <p className="three-dots">
          <span></span>
        </p>
        {project.title.rendered}
      </h4>
      <div className="card-body">
        <div className="card-image">
          <img
            src={project.uagb_featured_image_src.full[0]}
            alt={project.title.rendered}
          />
        </div>
        <div className="card-content">
          <p className="project-desc">{project.uagb_excerpt}</p>
        </div>
        <ul className="project-links">
            {project.ACF.project_links.code_link !== "" ? (
              <li>
                <a href={project.ACF.project_links.code_link}><FaGithub/>View Code</a>
              </li>
            ) : (
              ""
            )}

            {project.ACF.project_links.design_link !== "" ? (
              <li>
                <a href={project.ACF.project_links.design_link}><FaBehance/>View Design</a>
              </li>
            ) : (
              ""
            )}

            {project.ACF.project_links.project_link !== "" ? (
              <li>
                <a href={project.ACF.project_links.project_link}>
                  <FaLink/>View Project
                </a>
              </li>
            ) : (
              ""
            )}
          </ul>
      </div>
    </div>
  );
};

export default Card;
