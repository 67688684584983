import { FaHtml5, FaCss3Alt, FaJs, FaPhp, FaWordpress, FaVuejs,FaSass, FaReact, FaNode, FaLaravel, FaJava, FaGulp, FaBootstrap, FaAngular } from "react-icons/fa";

const Skills = () => {
  return (
    <section className="Skills">
      <div className="container">
        <h3>Skills</h3>
        <ul className="skills-list">
          <li><FaHtml5/></li>
          <li><FaCss3Alt/></li>
          <li><FaJs/></li>
          <li><FaPhp/></li>
          <li><FaWordpress/></li>
          <li><FaVuejs/></li>
          <li><FaSass/></li>
          <li><FaReact/></li>
          <li><FaNode/></li>
          <li><FaLaravel/></li>
          <li><FaJava/></li>
          <li><FaGulp/></li>
          <li><FaBootstrap/></li>
          <li><FaAngular/></li>
        </ul>
      </div>
    </section>
  );
};

export default Skills;
