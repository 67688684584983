import Hero from "./Hero";
import Nav from "./Nav";

const Banner = () => {
  return (
    <section className="banner">
      <div className="container">
        <div className="banner-row">
          <Hero />
          <Nav />
        </div>
      </div>
    </section>
  );
};

export default Banner;
