import Card from "./Global/Card";
import axios from "axios";
import { useState, useEffect } from "react";
const { REACT_APP_BASE_URL } = process.env;

const Projects = () => {
  const [projects, setProject] = useState([]);

  useEffect(() => {
    axios.get(`${REACT_APP_BASE_URL}/projects`).then((response) => {
      setProject(response.data);
    });
  }, []);

  if (!projects) return null;

  return (
    <section id="projects" className="projects">
      <div className="container">
        <h3>Projects</h3>
        <div className="cards-row">
          {projects.map((project) => (
            <Card project={project} key={project.id} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Projects;
