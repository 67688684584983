import { FaFacebookF, FaTwitter, FaGithub, FaLinkedinIn } from "react-icons/fa";

const Social = () => {
  return (
    <ul className="socialMenuList">
      <li>
        <a href="/">
          <FaFacebookF />
        </a>
      </li>
      <li>
        <a href="/">
          <FaTwitter />
        </a>
      </li>
      <li>
        <a href="/">
          <FaGithub />
        </a>
      </li>
      <li>
        <a href="/">
          <FaLinkedinIn />
        </a>
      </li>
    </ul>
  );
};

export default Social;
