import axios from "axios";
import { useState, useEffect } from "react";
const { REACT_APP_BASE_URL } = process.env;

const About = () => {

  const [pageData, setPageData] = useState([]);

  useEffect(() => {
    axios.get(`${REACT_APP_BASE_URL}/pages/?slug=portfolio-home`).then((response) => {
      setPageData(response.data);
    })

  },[])

  if (!pageData) return null;


  console.log(pageData);

  return (
    <section id="about" className="About">
      <div className="container">
        <h3>About</h3>
        {pageData.map((post) => (
          <div className="about-row" key={post.id}>
          
            <div className="about-desc">
              {post.ACF.about_content.about_description}
            </div>
            <img
              src={post.ACF.about_content.about_image.url}
              alt=""
            />
          </div>
        ))}
        
      </div>
    </section>
  );
};

export default About;
