import PostListCard from "./../Global/PostListCard";
import axios from "axios";
import { useState, useEffect } from "react";
const { REACT_APP_BASE_URL } = process.env;

const PostsList = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    axios.get(`${REACT_APP_BASE_URL}/posts`).then((response) => {
      setPosts(response.data);
    });
  }, []);

  if (!posts) return null;

  return (
    <section id="PostsList" className="PostsList">
      <div className="container">
        <h3>Blog</h3>
        <div className="postcards-row">
          {posts.map((post) => (
            <PostListCard post={post} key={post.id} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default PostsList;
