const PostListCard = ({ post }) => {
  const date = new Date(post.date_gmt).toLocaleDateString([], {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  return (
    <div className="PostListCard">
      <h4>
        <a href={post.link}>{post.title.rendered}</a>
      </h4>
      <p className="postListMeta">
        Published on {date} by {post.uagb_author_info.display_name}
      </p>
      <p className="postListExcerpt">{post.uagb_excerpt}</p>
      <a href={post.link}>Read more...</a>
    </div>
  );
};

export default PostListCard;
