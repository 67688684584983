// import { gsap } from "gsap";
import axios from "axios";
import { useState, useEffect } from "react";
const { REACT_APP_BASE_URL } = process.env;



const Hero = () => {
  const [pageData, setPageData] = useState("");

  useEffect(() => {
    axios
      .get(`${REACT_APP_BASE_URL}/pages/?slug=portfolio-home`)
      .then((response) => {
        setPageData(response.data);
      });

      // const t1 = gsap.timeline({ defaults: { ease: "power1.out" } });
      // t1.fromTo(".hero", { opacity: 0, delay:2 }, { opacity: 1, duration: 1 });

  });

  if (!pageData) return null;

  return (
    <div className="hero">
      {pageData.map((data) => (
        <div key={data.id}>
          <h1>
            Hello<span>.</span>
          </h1>
          <h2>I am Shubham Dobriyal</h2>
          <h3 className="leftBar">
            Senior Software Engineer at{" "}
            <a
              target={data.ACF.banner_content.current_company.target}
              href={data.ACF.banner_content.current_company.url}
            >
              {data.ACF.banner_content.current_company.title}
            </a>
          </h3>
        </div>
      ))}
    </div>
  );
};

export default Hero;
