import Social from "./../Global/Social";
import { FaBars, FaTimes } from "react-icons/fa";
import { useState } from 'react';
import { Link } from "react-scroll";

const Nav = () => {



    const [active, setActive] = useState('false');

    function menuToggle(){
      setActive(!active);
    }

    


  return (
    <div className={`nav ${active ?  '' : 'menuOpen' }`}>
      <i className="menuToggle" onClick={menuToggle}>
        {active ?  <FaBars /> : <FaTimes />}
        
      </i>
      <ul className="navMenuList">
        <li>
          <Link activeClass="active" to="about" spy={true} smooth={true} duration={500}>About</Link>
          {/* <a to="about" href="/">About</a> */}
        </li>
        <li>
          <Link to="projects" spy={true} smooth={true} duration={500} >Projects</Link>
          {/* <a to="projects" href="/">Projects</a> */}
        </li>
        <li>
          <Link to="PostsList" spy={true} smooth={true} duration={500} >Blog</Link>
          {/* <a to="PostsList" href="/">Blog</a> */}
        </li>
        <li>
          <Link to="contact-us" spy={true} smooth={true} duration={500} >Contact me</Link>
          {/* <a to="contact-us" href="/">Contact me</a> */}
        </li>
      </ul>
      <Social />
    </div>
  );
};

export default Nav;
