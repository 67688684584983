import Social from "./Global/Social";

const Footer = () => {
  return (
    <footer id="contact-us">
      <div className="container">
        <p>
          Say Hello @{" "}
          <a href="mailto:shubhdobriyal15@gmail.com">
            shubhdobriyal15@gmail.com
          </a>
        </p>
        <div>
          or find me on
          <Social />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
