import './App.css';
import "./sass/style.scss";

import { IntroPreloader, Banner, Projects, About, Skills, PostsList, Footer, CursorFollowCircle } from "./components";

function App() {

  return (
    <div className="App theme-light">
      <CursorFollowCircle/>
      <IntroPreloader/>
      <Banner/>
      <Projects />
      <About />
      <Skills />
      <PostsList />
      <Footer />
    </div>
  );
}

export default App;
