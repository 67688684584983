import { useEffect } from "react";
import { gsap } from "gsap";

const Preloader = () => {
  useEffect(() => {
    const tl = gsap.timeline({ defaults: { ease: "power1.out" } });

    tl.to(".text", { x: "0%", duration: 1, stagger: 0.25 });
    tl.to(".slider", { x: "100%", duration: 1.5, delay: 1 });
    tl.to(".intro-screen", { x: "100%", duration: 1 }, "-=1");
  });

  return (
    <div className="preloader">
      <div className="intro-screen">
        <div className="intro-text">
          <h1 className="slide-hide">
            <span className="text">Hello!</span>
          </h1>
        </div>
      </div>
      <div className="slider"></div>
    </div>
  );
};

export default Preloader;
